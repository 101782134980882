2<template>

    <div class="w-full h-full px-3 py-4">
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
            <div class="h-12 flex flex-row px-2">
                <div class="h-full w-10/12 flex flex-row">
                    <div class="h-full w-full flex flex-col justify-center items-start">
                        <span class="text-2xl font-semibold" :class="'text-dfont-'+mode">{{$t('filters')}}</span>
                    </div>
                </div>
                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <!-- <i class="mdi mdi-dots-vertical text-gray text-xl"></i> -->
                </div>
            </div>

            <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                        <!-- <el-select v-model="period" :placeholder="$t('select')" class="w-full">
                            <el-option
                                    v-for="item in periods"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                    class="w-full">
                            </el-option>
                        </el-select> -->
                        <select name="period" v-model="period" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <filter-months v-if="period == 'month'"   :mode='mode'/> -->
            <div class="h-10 mt-6 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center" @click="applyfilters()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>

            <div class="h-6"></div>
        </div>
    </div>

</template>

<script>
    import { All } from '@/api/components';
    import { state, actions } from '@/store';

    export default {
        components:{
            All
        },
        data () {
            return{
                periods: [
                    {
                        name: this.$t("monthly"),
                        value: 'month'
                    },
                    {
                        name: this.$t("quarterly"),
                        value: 'quarter'
                    },
                    {
                        name: this.$t("annual"),
                        value: 'year'
                    }
                ],
                period: '',
            }
        },
        methods:{
            applyfilters() {
                actions.setPeriodAnalitycs(this.period)
                this.$router.push( {name:'analitycsUser'} )
            }
        },
        computed: {
            mode(){
                return state.mode
            }
        },
        mounted() {
            this.period = state.periodAnalitycs
        }
    }
</script>

<style>
    /* .el-input__inner{
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */
</style>